@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Muller Light;
    font-weight: 300;
    src: url('./assets/fonts/MullerLight.ttf') format('truetype');
  }
  @font-face {
    font-family: Muller Regular;
    font-weight: 400;
    src: url('./assets/fonts/MullerRegular.ttf') format('truetype');
  }
  @font-face {
    font-family: Muller Medium;
    font-weight: 500;
    src: url('./assets/fonts/MullerMedium.ttf') format('truetype');
  }
  @font-face {
    font-family: Muller Bold;
    font-weight: 700;
    src: url('./assets/fonts/MullerBold.ttf') format('truetype');
  }
  @font-face {
    font-family: Graphie;
    font-weight: 600;
    src: url('./assets/fonts/GraphieSemibold.ttf') format('truetype');
  }
  body {
    @apply font-muller_medium text-main_light bg-main_dark;
  }
}